<template>
  <VueSpinner size="20" color="red" />
  <div id="profileData" class="onr-student-page profile-heading">
    <span class="profile-greeting">Thank you,</span>
    <h1 class="onr-student-page profile-heading--header">{{ authUser.displayName }}</h1>
  </div>
  <div class="onr-student-page profile-content">
    <div class="onr-student-page profile-menu">
      <template v-for="(item, index) in menuItems" :key="index">
        <div v-if="item.role === 'All' || item.role === loadedUser.role">
          <button class="profile-menu--item" :class="menuSelectedClasses(item)" @click="menuOpen(item)">
            {{ item.label }}
          </button>
        </div>
      </template>
    </div>
    <div v-if="selectedItem.value === 'profile'" class="onr-student-page profile-menu__content">
      <div class="onr-student-page content-container">
        <h2>{{ selectedItem.label }}</h2>
        <button v-if="!authUser.emailVerified" class="edit-account__button" type="submit"
                @click="sendVerificationEmail()">
          Email Verification
        </button>
      </div>
      <div class="onr-student-page profile-image-container">
        <ImageUploader/>
      </div>
      <div class="edit-account">
        <div class="input__container">
          <span class="input__label">First name</span>
          <input v-model="loadedUser.firstName" class="onr-body__create-form--full" type="text" name="firstname"
                 :maxlength="maxInputLength" required>
        </div>
        <div class="input__container">
          <span class="input__label">Last name</span>
          <input v-model="loadedUser.lastName" class="onr-body__create-form--full" type="text" name="lastname"
                 :maxlength="maxInputLength" required>
        </div>
        <div class="input__container">
          <span class="input__label">Email</span>
          <input :value="loadedUser.email" disabled :readonly="true" class="onr-body__create-form--full" type="text"
                 name="email" @input="validateEmail" required>
        </div>
        <span v-if="!isValidEmail" class="input__error">
          Please enter a valid email address.
        </span>
        <div class="input__container">
          <span class="input__label">Gender</span>
          <select v-model="loadedUser.gender" :disabled="true" class="onr-body__create-form--full dropdown--gender"
                  required>
            <option disabled value="">Please select one</option>
            <option value="M">Male</option>
            <option value="F">Female</option>
          </select>
        </div>
        <div class="input__container">
          <span class="input__label">Date of birth</span>

          <VueDatePicker
              class="onr-body__create-form--full  v3dp__datepicker"
              v-model="myDOB"
              :format="dobFormat"
              :text-input="textInputOptions"
              :enable-time-picker="false"
              :timezone="{ timezone: 'UTC', exactMatch: true, dateInTz: 'UTC' }"
          />
        </div>
        <div class="input__container">
          <span class="input__label">Nationality</span>
          <select v-model="loadedUser.country" class="onr-body__create-form--full dropdown--country" required>
            <option disabled value="">Please select one</option>
            <template v-for="(country, index) in countries" :key="index">
              <option :value="country.code">{{ country.name }}</option>
            </template>
          </select>
        </div>
        <div class="input__container">
          <span class="input__label">Residence</span>
          <select v-model="userResidence" class="onr-body__create-form--full dropdown--country" required>
            <option disabled value="">Please select one</option>
            <template v-for="(country, index) in countries" :key="index">
              <option :value="country.code">{{ country.name }}</option>
            </template>
          </select>
        </div>
        <div class="input__container">
          <span class="input__label">City</span>
          <input v-model="loadedUser.location" class="onr-body__create-form--full" type="text" name="location"
                 :maxlength="maxInputLength" required>
        </div>
        <div class="input__container">
          <span class="input__label">Phone</span>
          <input v-model="loadedUser.phone" class="onr-body__create-form--full" type="text" name="phone"
                 :maxlength="maxInputLength" required>
        </div>
        <div class="input__container">
          <span class="input__label">Occupation</span>
          <input v-model="loadedUser.occupation" class="onr-body__create-form--full" type="text" name="occupation"
                 :maxlength="maxInputLength" required>
        </div>
        <div class="input__container">
          <span class="input__label">Interests</span>
          <input v-model="loadedUser.interests" class="onr-body__create-form--full" type="text" name="interests"
                 :maxlength="maxInputLength" required>
        </div>
        <div class="input__container">
          <span class="input__label">Field of study</span>
          <input v-model="loadedUser.academicFields" class="onr-body__create-form--full" type="text"
                 name="academicFields" :maxlength="maxInputLength" required>
        </div>
      </div>
      <button class="edit-account__button" type="submit" @click="updateProfile()">
        Update
      </button>
    </div>
    <div v-if="selectedItem.value === 'availabilities' && loadedUser.role === 'Student'"
         class="onr-student-page profile-menu__content">
      <h2>{{ selectedItem.label }}</h2>
      <div class="onr-student-page content-body">
        <p>My current available times:</p>
        <ul>
          <li v-for="(item, index) in JSON.parse(loadedUser.availableTimes)" :key="index">
            {{ item }}
          </li>
        </ul>
      </div>
      <Meetingselector @getMeetings="handleMeetings"/>
      <button class="edit-account__button" type="submit" @click="changeAvailabilities()">
        Update
      </button>
    </div>
    <div v-if="selectedItem.value === 'class-history'" class="onr-student-page profile-menu__content">
      <h2>{{ selectedItem.label }}</h2>
      <div v-if="classHistory" class="onr-student-page content-body">
        <Accordion
            v-for="(item, index) in classHistory"
            :key="index"
            :question="item.schedule"
            :content="getClassHistory(item)"
        ></Accordion>
      </div>
    </div>
    <div v-if="selectedItem.value === 'my-class'" class="onr-student-page profile-menu__content">
      <h2>{{ selectedItem.label }}</h2>
      <div v-if="myClass">
        <p>Subject: {{ myClass.subject }}</p>
        <p>Teacher: {{ myClass.teacherName }}</p>
        <p>Student: {{ myClass.studentName }}</p>
        <p v-if="loadedUser.role === 'Teacher'">Country: {{ getNameByCode(myClass['student'].country) }}</p>
        <p v-if="loadedUser.role === 'Teacher'">Residence: {{ getNameByCode(myClass['student'].residence) }}</p>
        <p v-if="loadedUser.role === 'Teacher'">Occupation: {{ myClass['student'].occupation }}</p>
        <p v-if="loadedUser.role === 'Teacher'">Interests: {{ myClass['student'].interests }}</p>
        <p v-if="loadedUser.role === 'Teacher'">Academic Fields: {{ myClass['student'].academicFields }}</p>

        <p>Meeting Link: <a v-bind:href="myClass.link" target="_blank">{{ myClass.link }}</a></p>
        <p>Upcoming Schedule: </p>
        <ul v-if="loadedUser.role === 'Teacher'">
          <li v-for="(date, index) in makeSchedule(getSimpleDateTime(myClass.schedule))" :key="index">
            {{ date }} (ET)
          </li>
        </ul>
        <ul v-if="loadedUser.role === 'Student'">
          <li v-for="(date, index) in makeSchedule(getSimpleDateTime(myClass.schedule))" :key="index">
            {{ date }} (AST/TRT)
          </li>
        </ul>

        <h2>Send a message to your {{ reverseRole() }}</h2>
        <div class="onr-student-page content-body">
          <div class="onr__contact-partner-container--form">
            <div class="input__container contact-partner">
              <span class="input__label contact-partner">To</span>
              <input v-model="contactForm.to" read-only disabled class="onr-body__create-form--full contact-partner"
                     type="text" name="to">
            </div>
            <div class="input__container contact-partner">
              <span class="input__label contact-partner">From</span>
              <input v-model="loadedUser.email" read-only disabled class="onr-body__create-form--full contact-partner"
                     type="text" name="from">
            </div>
            <div class="input__container contact-partner">
              <span class="input__label contact-partner">Message</span>
              <textarea v-model="contactForm.message" class="onr-body__create-form--full textarea contact-partner"
                        rows="8" cols="50" type="textarea" name="message"></textarea>
            </div>
            <button class="send-message-button contact-partner" @click="sendMessage()">Send Message</button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="selectedItem.value === 'weekly-reports'" class="onr-student-page profile-menu__content">
      <table v-show="true" class="reports-table">
        <tr>
          <th>No</th>
          <th>Evaluation report</th>
          <th>Submitted date</th>
          <!--<th>Due date</th>-->
          <th>Status</th>
          <th>View</th>
        </tr>
        <tr v-for="(item, index) in evals" :key="index">
          <td>{{ index + 1 }}</td>
          <td @click="reportClick(reports[index])" class="report-state">
            {{ myClass?.subject }} Weekly Report
          </td>
          <td>{{ reports[index] ? reports[index].submitted_date.split('T')[0] : 'N/A' }}</td>
          <td>
            <svg v-if="reports[index] && reports[index].no == index + 1" width="20px" height="20px"
                 viewBox="0 0 1024 1024" class="icon" version="1.1"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M512 64l100.266667 76.8 123.733333-17.066667 46.933333 117.333334 117.333334 46.933333-17.066667 123.733333L960 512l-76.8 100.266667 17.066667 123.733333-117.333334 46.933333-46.933333 117.333334-123.733333-17.066667L512 960l-100.266667-76.8-123.733333 17.066667-46.933333-117.333334-117.333334-46.933333 17.066667-123.733333L64 512l76.8-100.266667-17.066667-123.733333 117.333334-46.933333 46.933333-117.333334 123.733333 17.066667z"
                  fill="#8BC34A"/>
              <path
                  d="M738.133333 311.466667L448 601.6l-119.466667-119.466667-59.733333 59.733334 179.2 179.2 349.866667-349.866667z"
                  fill="#CCFF90"/>
            </svg>
          </td>
          <td>
            <div v-on:click="fetchReport(reports[index].no)"><img src="view.png" width="20"></div>
          </td>
        </tr>
      </table>

      <template v-if="showForm()">
        <h2>{{ myClass.subject }} Weekly Report
          {{ report_no }}</h2>
        <div class="onr-student-page content-body eval-form-container">
          <div class="onr__eval-container--form">
            <div class="input__container eval-form">
              <span class="input__label eval-form">Instructor name</span>
              <input v-model="myClass.teacherName" read-only disabled class="onr-body__create-form--full eval-form"
                     type="text" name="name" required>
            </div>
            <div class="input__container eval-form">
              <span class="input__label eval-form">Instructor email</span>
              <input v-model="loadedUser.email" read-only disabled class="onr-body__create-form--full eval-form"
                     type="text" name="email" required>
            </div>
            <template
                v-for="(item, index) in report_form"
                :key="index"
            >
              <div class="input__container eval-form">
                <span class="input__label eval-form">{{ item.question }}</span>
                <input v-if="item.type === 'short'" v-model="item.response"
                       class="onr-body__create-form--full eval-form" type="text" name="short">
                <textarea v-if="item.type === 'long'" v-model="item.response"
                          class="onr-body__create-form--full eval-form" rows="4" cols="50" type="textarea"
                          name="long"></textarea>
                <label v-if="item.type === 'radio'">
                  <input type="radio"
                         v-if="item.type === 'radio'"
                         v-model="item.response" name="radio" value="Yes" class="radio-option"
                  />Yes</label>
                <label v-if="item.type === 'radio'">
                  <input type="radio"
                         v-if="item.type === 'radio'"
                         v-model="item.response" name="radio" value="No" class="radio-option"
                  />No</label>
              </div>
            </template>
            <button class="submit-eval-form" @click="submitEvalForm()">Submit Evaluation</button>
          </div>
        </div>
      </template>
      <template v-if="showReport()">
        <h2>{{ myClass.subject }} Weekly Report
          {{ this.reportId }}</h2>
        <div class="onr-student-page content-body eval-form-container">
          <div class="onr__eval-container--form">
            <div class="input__container eval-form">
              <span class="input__label eval-form">Instructor name</span>
              <input v-model="myClass.teacherName" read-only disabled class="onr-body__create-form--full eval-form"
                     type="text" name="name" required>
            </div>
            <div class="input__container eval-form">
              <span class="input__label eval-form">Instructor email</span>
              <input v-model="loadedUser.email" read-only disabled class="onr-body__create-form--full eval-form"
                     type="text" name="email" required>
            </div>
            <template
                v-for="(item, index) in report"
                :key="index"
            >
              <div class="input__container eval-form">
                <span class="input__label eval-form">{{ item.question }}</span>
                <input v-if="item.type === 'short'" v-model="item.response"
                       class="onr-body__create-form--full eval-form" type="text" name="short" readonly>
                <textarea v-if="item.type === 'long'" v-model="item.response"
                          class="onr-body__create-form--full eval-form" rows="4" cols="50" type="textarea" readonly
                          name="long"></textarea>
                <label v-if="item.type === 'radio'">
                  <input type="radio"
                         v-if="item.type === 'radio'"
                         v-model="item.response" name="radio" value="Yes" class="radio-option" readonly
                  />Yes</label>
                <label v-if="item.type === 'radio'">
                  <input type="radio"
                         v-if="item.type === 'radio'"
                         v-model="item.response" name="radio" value="No" class="radio-option" readonly
                  />No</label>
              </div>
            </template>
            <button class="submit-eval-form" @click="toggleContent()">Close</button>
          </div>
        </div>
      </template>
    </div>
    <div v-if="selectedItem.value === 'meeting-info'" class="onr-student-page profile-menu__content">
      <h2>{{ selectedItem.label }}</h2>
      <div class="onr-student-page content-body">
        <p>Zoom or Google Meet link: <a v-bind:href="zoomLink" target="_blank">{{ zoomLink }}</a></p>
        <span class="onr-student-page input__label">Update Zoom link:</span>
        <input v-model="inputLink" class="onr-student-page zoom-link" type="text" name="zoom-link">
        <button class="onr-student-page zoom-update-button" type="submit" @click="updateZoomLink()">Update</button>
      </div>
    </div>
    <div v-if="selectedItem.value === 'evaluation'" class="onr-student-page profile-menu__content">
      <h2>{{ selectedItem.label }}</h2>
      <div class="onr-student-page content-body">
        <p>Evaluation document located at: </p>
      </div>
    </div>
    <div v-if="selectedItem.value === 'deactivate'" class="onr-student-page profile-menu__content">
      <h2>{{ selectedItem.label }} </h2>
      <div v-if="myStatus === activateStatus" class="onr-student-page content-body deactivate">
        <h3>Activated</h3>
        <span>If you no longer wish to continue in this program, click to deactivate:</span>
        <button class="onr-student-page deactivate-button" @click="updateMyStatus(deactivateStatus)">Deactivate
          account
        </button>
      </div>
      <div v-if="myStatus === deactivateStatus" class="onr-student-page content-body deactivate">
        <h3>Deactivated</h3>
        <span>If you wish to rejoin this program, click to activate:</span>
        <button class="onr-student-page activate-button" @click="updateMyStatus(activateStatus)">Activate account
        </button>
      </div>
      <div v-if="myStatus === registeredStatus" class="onr-student-page content-body deactivate">
        <h3>Registered</h3>
        <span>You're currently taking the course</span>
        <!--<button class="onr-student-page activate-button" @click="updateMyStatus(activateStatus)">Activate account
        </button>-->
      </div>
    </div>
  </div>
  <AlertOverlay :showModal="showUpdateOverlay" @close="close"></AlertOverlay>
</template>

<script>
//import {isEmpty} from 'lodash';
// import EditAccountOverlay from '../components/overlays/editAccountOverlay.vue';
import Accordion from '../components/accordion.vue';
import {getUser, getVideoLink, updateStudentStatus, updateUser, updateVideoLink} from "@/api";
import {setAvailablities} from "@/api/students";
import {openUploadModal} from "@bytescale/upload-widget-vue";
import ImageUploader from '../components/ImageUploader.vue';
import countries from '@/data/countries.json';
import Meetingselector from "@/components/meetingselector/meetingselector.vue";
//import DatePicker from 'vue3-datepicker';
import AlertOverlay from '../components/overlays/alertOverlay.vue';
//import RadioButton from '@/components/radioButton.vue';
import {getClassHistory, getMyClass} from "@/api/classes";
import {sendMsg} from "@/api/email";
import {sendEmailVerification} from "firebase/auth";
import {auth} from "@/firebase.config";
import {createReport, getReports, getReportQuestions, getReport} from "@/api/reports";
import {
  VueSpinner,
  // ...
} from 'vue3-spinners';
import moment from "moment";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  name: 'StudentPage',
  components: {
    VueDatePicker,
    Accordion,
    ImageUploader,
    Meetingselector,
    AlertOverlay,
    VueSpinner,
  },
  data() {
    return {
      textInputOptions: {
        format: 'MM/dd/yyyy'
      },
      dobFormat: 'MM/dd/yyyy',
      showContent: false,
      report: null,
      reportId: 0,
      contactForm: {
        to: '',
        from: '',
        message: '',
      },
      form: [
        {
          question: 'What date/time was your class?',
          type: 'short',
          response: '',
        },
        {
          question: 'How did you run the first class? (eg. Icebreaking, level tests, conversational, any topics talked about, any topics taught etc.)',
          type: 'long',
          response: '',
        },
        {
          question: 'Were there any difficulties holding the class?',
          type: 'long',
          response: '',
        },
        {
          question: 'Were you able to hold the class without help from the local teachers?',
          type: 'radio',
          response: '',
        },
        {
          question: 'Please share any additional details of your student (job, family, hobbies, specialties, dream job, etc.) that could be helpful for the local teachers.',
          type: 'long',
          response: '',
        },
        {
          question: 'If you had a chance to talk about, what is the student\'s motive/purpose/expectations from this program?',
          type: 'long',
          response: '',
        },
        {
          question: 'How was the student\'s attitude/behavior during the class?',
          type: 'long',
          response: '',
        },
        {
          question: 'Was there anything that the student suggested to add, or any topics they wanted to learn?',
          type: 'long',
          response: '',
        },
        {
          question: 'How did you feel about the length of this class (1 hour)?',
          type: 'long',
          response: '',
        },
        {
          question: 'Please share any other feedback or suggestions!',
          type: 'long',
          response: '',
        },
        {
          question: 'Please share any prayer request(s) for this week. We will be praying with you.',
          type: 'long',
          response: '',
        }
      ],
      overlayContent: {
        heading: 'Are you sure you want to update?',
        content: '',
      },
      evals: [
        {
          week: 1,
          subject: 'SBS',
          season: '8',
          report: 'SBS Season 8 Report Form',
          dueDate: 'Jan 1, 2024',
          dateUploaded: 'Oct 1, 2023',
        },
        {
          week: 2,
          subject: 'SBS',
          season: '8',
          report: 'SBS Season 8 Report Form',
          dueDate: 'Jan 1, 2024',
          dateUploaded: 'Oct 17, 2023',
        },
        {
          week: 3,
          subject: 'SBS',
          season: '8',
          report: 'SBS Season 8 Report Form',
          dueDate: 'Jan 1, 2024',
          dateUploaded: '',
        },
        {
          week: 4,
          subject: 'SBS',
          season: '8',
          report: 'SBS Season 8 Report Form',
          dueDate: 'Jan 1, 2024',
          dateUploaded: '',
        },
        {
          week: 5,
          subject: 'SBS',
          season: '8',
          report: 'SBS Season 8 Report Form',
          dueDate: 'Jan 1, 2024',
          dateUploaded: '',
        },
        {
          week: 6,
          subject: 'SBS',
          season: '8',
          report: 'SBS Season 8 Report Form',
          dueDate: 'Jan 1, 2024',
          dateUploaded: '',
        },
        {
          week: 7,
          subject: 'SBS',
          season: '8',
          report: 'SBS Season 8 Report Form',
          dueDate: 'Jan 1, 2024',
          dateUploaded: '',
        },
        {
          week: 8,
          subject: 'SBS',
          season: '8',
          report: 'SBS Season 8 Report Form',
          dueDate: 'Jan 1, 2024',
          dateUploaded: '',
        },
      ],
      maxInputLength: 64,
      deactivateStatus: 4000,
      registeredStatus: 2000,
      activateStatus: 1000,
      showUpdateOverlay: false,
      authUser: {},
      user: {},
      loadedUser: {},
      zoomLink: '',
      inputLink: '',
      myStatus: '',
      myClass: {},
      classHistory: [],
      myDOB: null,
      myAvailabilities: [],
      reports: [],
      report_no: 0,
      report_form: [],
      menuItems: [
        {
          label: 'Personal Info',
          value: 'profile',
          role: 'All',
          content: {},
        },
        // {
        //   label: 'List of instructors',
        //   value: 'list-instructors',
        //   role: 'Admin',
        //   content: {},
        // },
        // {
        //   label: 'List of students',
        //   value: 'list-students',
        //   role: 'Admin',
        //   content: {},
        // },
        {
          label: 'Availabilities',
          value: 'availabilities',
          role: 'Student',
          content: {},
        },
        {
          label: 'Class History',
          value: 'class-history',
          role: 'All',
          content: [],
        },
        {
          label: 'Meeting Info',
          value: 'meeting-info',
          role: 'Teacher',
          content: [],
        },
        {
          label: 'My Class',
          value: 'my-class',
          role: 'All',
          content: [
            {}
          ],
        },
        {
          label: 'Weekly Reports',
          value: 'weekly-reports',
          role: 'Teacher',
          content: [
            {}
          ],
        },
        {
          label: 'My Status',
          value: 'deactivate',
          role: 'All',
          content: {
            link: 'DEACTIVATE_LINK',
          },
        },
      ],
      selectedItem: {},
      isValidPassword: true,
      isValidEmail: true,
      showEmailError: false,
      showPasswordError: false,
      subjects: [
        {
          key: 'sbs',
          label: 'SBS(English)',
          checked: false,
        },
        {
          key: 'lbl_coding',
          label: 'LBL(Coding)',
          checked: false,
        },
        {
          key: 'lbl_office',
          label: 'LBL(MS Office)',
          checked: false,
        },
        {
          key: 'han',
          label: 'Hanbud(Korean)',
          checked: false,
        }
      ],
      interests: [
        {
          key: 'cooking',
          label: 'Cooking',
          checked: false,
        },
        {
          key: 'kpop',
          label: 'K-Pop',
          checked: false,
        },
        {
          key: 'kdrama',
          label: 'Korean Drama',
          checked: false,
        },
        {
          key: 'sports',
          label: 'Sports',
          checked: false,
        },
        {
          key: 'travel',
          label: 'Travel',
          checked: false,
        },
        {
          key: 'reading',
          label: 'Reading',
          checked: false,
        },
        {
          key: 'art',
          label: 'Art',
          checked: false,
        },
        {
          key: 'dance',
          label: 'Dance',
          checked: false,
        },
        {
          key: 'fashion',
          label: 'Fashion',
          checked: false,
        },
        {
          key: 'photography',
          label: 'Photography',
          checked: false,
        }
      ],
      studyFields: [
        {
          key: 'accounting',
          label: 'Accounting',
          checked: false,
        },
        {
          key: 'economics',
          label: 'Economics',
          checked: false,
        },

        {
          key: 'education',
          label: 'Education',
          checked: false,
        },
        {
          key: 'engineering',
          label: 'Engineering',
          checked: false,
        },
        {
          key: 'finance',
          label: 'Finance',
          checked: false,
        },
        {
          key: 'language',
          label: 'Language',
          checked: false,
        },
        {
          key: 'law',
          label: 'Law',
          checked: false,
        },
        {
          key: 'marketing',
          label: 'Marketing',
          checked: false,
        },
        {
          key: 'math',
          label: 'Mathematics',
          checked: false,
        },
        {
          key: 'medical',
          label: 'Medical & Health',
          checked: false,
        },
        {
          key: 'music',
          label: 'Music',
          checked: false,
        },
        {
          key: 'pharmacy',
          label: 'Pharmacy',
          checked: false,
        },
        {
          key: 'religious',
          label: 'Religious Studies',
          checked: false,
        },
        {
          key: 'science',
          label: 'Science',
          checked: false,
        },
        {
          key: 'technology',
          label: 'Technology',
          checked: false,
        },
        {
          key: 'other',
          label: 'Other',
          checked: false,
        },

      ],
      selectedReport: {},
    };
  },
  computed: {

    userResidence() {
      return this.user.residence;
    },
    studentData() {
      //  TODO - call to get student/teacher match data
      const studentData = [
        {
          season: '2023',
          students: [
            {
              id: '',
              name: 'Jenny',
              nationality: 'Arabia',
              dob: '10/2/1991',
              interests: 'Interest A, B, C',
              evaluations: [
                {
                  phase: 'Week 1',
                  eval: 'Evaluation_Link',
                },
                {
                  phase: 'Week 2',
                  eval: 'Evaluation_Link',
                },
                {
                  phase: 'Week 3',
                  eval: 'Evaluation_Link',
                }
              ]
            },
          ],
        },
      ];
      return studentData;
    },
    countries() {
      return countries;
    },
  },
  mounted() {
    window.scrollTo({top: 0, behavior: 'smooth'});

    this.authUser = JSON.parse(localStorage.getItem('authUser'))
    this.loadUserInfo();

    this.selectedItem = this.menuItems[0];
    console.log('.this.selectedItem: ', this.selectedItem);
    //console.log('...status', this.myStatus)

  },
  methods: {
    getClassHistory(item) {
      return `
        Subject: ${item.subject}
        Teacher: ${item.teacherName}
        Student: ${item.studentName}
        Schedule: ${item.schedule}
      `;
    },
    async fetchReport(rid) {
      this.reportId = rid;
      console.log('fetchReport: ', rid);
      this.showContent = true; // Toggle the visibility of the content
      console.log('this.showContent: ', this.showContent)
      if (this.showContent) {
        getReport(this.myClass.cid, rid).then((resp) => {
          this.report = JSON.parse(resp[0].answer);
          console.log(this.report)
        }).catch((err) => {
          console.log('err: ', err);
        });
      }
    },
    toggleContent() {
      this.showContent = !this.showContent;
    },


    formatDate(date) {
      console.log("...", date, typeof date)
      return date.toString().slice(0, 21);
    },
    getNameByCode(countryCode) {
      const country = countries.find(country => country.code === countryCode);
      return country ? country.name : null;
    },
    sendVerificationEmail() {
      sendEmailVerification(auth.currentUser)
          .then(() => {
            alert('An email for verification has been sent.');
            console.log('Email verification sent!');
          });
    },
    makeSchedule(dateString) {
      const startDate = new Date(dateString);

      const numWeeks = 10;

      const dateList = Array.from({length: numWeeks}, (_, index) => {
        const newDate = new Date(startDate);
        newDate.setDate(startDate.getDate() + index * 7);

        return moment(newDate).format('MMM Do YYYY, h:mm:ss A')
      });
      console.log(dateList)
      return dateList;
    },
    convertUtcToEst(utcDateString) {
      // Create a Date object from the UTC date string
      var utcDate = new Date(utcDateString);

      // Convert UTC to EST
      var estOptions = {timeZone: 'America/New_York'};
      var estDateString = utcDate.toLocaleString('en-US', estOptions);

      return estDateString;
    },
    convertUtcToIstanbul(utcDateString) {
      // Create a Date object from the UTC string
      const utcDate = new Date(utcDateString);

      // Convert the UTC date to Istanbul time
      const istanbulDateString = utcDate.toLocaleString('en-US', {
        timeZone: 'Europe/Istanbul'
      });

      return istanbulDateString;
    },
    getSimpleDateTime(dateString) {
      if (!dateString) return ''
      console.log("...dateString", dateString)
      let date;

      if (this.user.role === "Teacher") {
        date = new Date(this.convertUtcToEst(dateString));
        console.log('...est', date)
      } else {
        if (this.user.country === "IL" || this.user.country === 'PS')
          date = new Date(this.convertUtcToIsrael(dateString));
        else
          date = new Date(this.convertUtcToIstanbul(dateString));
      }

      console.log("...date", date, typeof date)
/*
      const year = date.getFullYear();
      const month = date.getMonth() + 1; // Months are zero-indexed, so add 1
      const day = date.getDate();
      const hours = date.getHours();
      const minutes = date.getMinutes();
*/
// Create a simple date string
      //const simpleDateString = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day} ${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
      //const simpleDateString =
      return date
    },
    convertUtcToIsrael(utcDateString) {
      // Create a Date object from the UTC date string
      var utcDate = new Date(utcDateString);

      // Convert UTC to Israel Standard Time by setting the time zone to 'Asia/Jerusalem'
      var israelOptions = {timeZone: 'Asia/Jerusalem'};
      var israelDateString = utcDate.toLocaleString('en-US', israelOptions);

      return israelDateString;
    },

    sendMessage() {
      let uid = '';
      if (this.user.role === "Teacher") {
        uid = this.myClass.sid;
      } else if (this.user.role === "Student") {
        uid = this.myClass.tid;
      }
      sendMsg(uid, this.contactForm.message).then((resp) => {
        console.log('resp: ', resp);
        this.contactForm.message = '';
        alert('Your message is sent');
      }).catch((err) => {
        console.log('err: ', err);
        alert('Error');
      });

    }
    ,
    reverseRole() {
      return this.loadedUser.role === 'Student' ? 'Teacher' : 'Student';
    }
    ,
    showForm() {
      //return !isEmpty(this.selectedReport);
      return this.report_no != 0 && this.report_no < 9;
    },
    showReport() {
      //return !isEmpty(this.selectedReport);
      return this.showContent;
    },
    reportClick(item) {
      this.selectedReport = item;
      //this.report_no = item.week;
    }
    ,
    submitEvalForm() {
      // submit eval form
      createReport(this.myClass.cid, this.report_no, this.user.uid, this.user.firstName + " " + this.user.lastName, this.user.email, this.report_form, this.myClass['student'].uid, this.myClass.studentName, this.myClass.subject).then((resp) => {
        console.log('resp: ', resp);
        alert('Report submitted');
        window.location.reload();

      }).catch((err) => {
        console.log('err: ', err);
      });
    }
    ,
    formUpdated() {
      return
    }
    ,
    disableButtonClasses() {
      return this.formUpdated ? '' : 'button--disabled';
    }
    ,
    updateProfile() {

      updateUser(this.loadedUser, this.myDOB).then((resp) => {
        console.log('resp: ', resp);
        this.formUpdated = false;
        alert('Profile updated');
      }).catch((err) => {
        console.log('err: ', err);
      });
    }
    ,
    changeAvailabilities() {
      console.log('...change', this.myAvailablities, typeof this.myAvailablities);
      setAvailablities(this.myAvailablities).then((resp) => {
        console.log('resp: ', resp);
        this.loadedUser.availableTimes = JSON.stringify(this.myAvailablities);
        alert('Availabilities Updated');
      }).catch((err) => {
        console.log('err: ', err);
      });
    }
    ,
    handleMeetings(data) {

      this.myAvailablities = data
      //availabilities = data;
    }
    ,

    async loadUserInfo() {
      return getUser().then((resp) => {

        this.user = resp;
        this.myDOB = new Date(this.user.dob);
        //this.myDOB = this.user.dob.split("T")[0]
        this.myStatus = resp.status

        this.myAvailablities = JSON.parse(this.user.availableTimes)
        console.log('...avail', this.myAvailablities, typeof this.myAvailablities);
        getClassHistory(this.user.role).then((resp) => {
          console.log('resp: ', resp);
          //this.menuItems[2].content = resp;
          this.classHistory = resp;
        }).catch((err) => {
          console.log('err: ', err);
        });
        getMyClass(this.user.role).then((resp) => {
          console.log('resp: ', resp);
          this.myClass = resp.class;
          if (this.user.role === "Teacher")
            this.contactForm.to = resp.class.studentName;
          else if (this.user.role === "Student")
            this.contactForm.to = resp.class.teacherName;
          this.myClass['link'] = resp.link.url;
          this.myClass['student'] = resp.student;
          if (this.user.role === "Teacher") {
            getReports(this.myClass.cid) // TODO - call to get reports
                .then((resp) => {
                  console.log('resp: ', resp);
                  this.reports = resp;
                  this.report_no = resp.length + 1;
                  getReportQuestions(this.report_no).then((resp) => {
                    console.log('resp: ', resp);
                    this.report_form = resp;
                  }).catch((err) => {
                    console.log('err: ', err);
                  });
                }).catch((err) => {
              console.log('err: ', err);
            });
          }
        }).catch((err) => {
          console.log('err: ', err);
        });
        if (this.user.role === 'Teacher') {
          getVideoLink().then((resp) => {
            console.log('resp: ', resp);
            this.zoomLink = resp.url;
          }).catch((err) => {
            console.log('err: ', err);
          });

        }

      }).catch((err) => {
        console.log('err: ', err);
      });
    }
    ,
    formattedAvailabilities(user) {
      const availabilities = JSON.parse(user.availableTimes);
      return availabilities.join(',');
    }
    ,
    openUpdateConfirmModal() {
      this.showUpdateOverlay = true;
    }
    ,
    close() {
      this.showUpdateOverlay = false;
    }
    ,
    uploadFile(event) {
      const options = {
        apiKey: "public_12a1ygV2T7U4dsuQDoAzuEjwHbwX", // This is your API key.
        maxFileCount: 10
      };
      openUploadModal({
        event,
        options,
        onComplete: (files) => {
          if (files.length === 0) {
            alert("No files selected.");
          } else {
            alert(files.map(f => f.fileUrl).join("\n"));
          }
        }
      });
    }
    ,
    editInfo(val) {
      console.log('..val', val);
      // open edit
    }
    ,
    updateMyStatus(status) {
      // TODO -- call to deactivate
      // may need to reload/redirect
      console.log('de/activate');

      updateStudentStatus(status).then((resp) => {
        console.log('resp: ', resp);
        this.myStatus = status;
        this.myStatus === this.activateStatus ? alert('Account activated') : alert('Account deactivated');
      }).catch((err) => {
        console.log('err: ', err);
      });
    }
    ,
    studentInfo(item) {
      return `Name: ${item.name}\nNationality: ${item.nationality}\nDOB: ${item.dob}\ninterests: ${item.interests}`;
    }
    ,
    menuOpen(item) {
      /* TODO - call to get teacher status
      getTeacherStatus(this.authUser.uid).then((resp) => {
        console.log('resp: ', resp);
        if (resp.data.status === 1000) {
          this.selectedItem = item;
        } else {
          //alert('You are not an active teacher. Please contact your administrator.');
        }
      }).catch((err) => {
        console.log('err: ', err);
      });*/

      this.selectedItem = item;
    }
    ,
    menuSelectedClasses(item) {
      return this.selectedItem.value === item.value ? 'menu--selected' : '';
    }
    ,
    updateZoomLink() {
      // TODO - call to update zoom link
      console.log('updateZoomLink');
      const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)/;
      if (!urlRegex.test(this.inputLink)) {
        alert('Please enter a valid URL');
        return;
      }
      updateVideoLink(this.inputLink).then((resp) => {
        console.log('resp: ', resp);
        this.zoomLink = this.inputLink;
        this.inputLink = '';

        alert('Meeting Info Updated');
      }).catch((err) => {
        console.log('err: ', err);
      });
    }
    ,
  },
  watch: {
    user: {
      handler(val) {
        this.loadedUser = val;
      }
    }
    ,
  }
  ,
}
</script>

<style lang="scss">
@import '../utils/_colors.scss';

.onr {
  &-student-page {
    &.zoom-link {
      border: 1px solid $neutral-2;
      font-size: 14px;
      height: 2.3em;
      padding-left: 12px;
      margin-bottom: 10px;
      margin-right: 1rem;
    }

    &.zoom-update-button {
      background-color: $blue-1;
      border-radius: 25px;
      border: none;
      color: white;
      cursor: pointer;
      font-size: 16px;
      height: 40px;
      margin-right: 0.5rem;
      margin: auto;
      margin-top: 20px;
      margin-bottom: 20px;
      width: 80px;
    }

    &.input__label {
      color: $blue-1;
      margin-right: 1rem;
      font-size: 1.2em;
    }

    &.deactivate-button {
      background-color: $red-1;
      color: white;
      font-size: 16px;
      border: solid 3px $red-1;
      border-radius: 25px;
      font-weight: 400;
      margin-top: 20px;
      padding: 13px 15px;
      text-transform: uppercase;

      &:hover {
        background-color: $red-2;
        border: solid 3px $red-2;
        color: white;
        cursor: pointer;
      }
    }

    &.activate-button {
      background-color: $blue-1;
      color: white;
      font-size: 16px;
      border: solid 3px $blue-1;
      border-radius: 25px;
      font-weight: 400;
      margin-top: 20px;
      padding: 13px 15px;
      text-transform: uppercase;

      &:hover {
        background-color: $blue-2;
        border: solid 3px $blue-2;
        color: white;
        cursor: pointer;
      }
    }

    &__hero {
      margin: 0;

      &__image {
        margin: 0;
        width: 100%;
      }
    }

    &.content-body {
      font-size: 18px;
      width: 60%;
      margin-top: 2rem;

      &.deactivate {
        flex-direction: column;
        display: flex;
      }

      .onr-accordion {
        .onr-accordion-slidedown {
          .accordion-content {
            white-space: pre-wrap;
          }
        }
      }

      .content-label {
        color: $grey-1;
        padding-bottom: 0.5rem;
      }
    }

    &.profile-heading {
      text-align: left;
      padding-left: 2rem;
      background-color: $blue-1;
      padding: 2rem;
      color: white;
      margin-top: 105.76px;
    }

    &.profile-content {
      display: flex;
      flex-direction: row;
      margin-top: 2rem;
      margin-bottom: 4rem;

      @media only screen and (max-width: 570px) {
        flex-direction: column;
      }
    }

    &.content-container {
      display: flex;
      align-items: baseline;
    }

    &.edit-info {
      margin-left: 1px;
      order: 2;
      margin-right: 1rem;
      cursor: pointer;
      color: $blue-2;

      .edit-icon {
        width: 15px;
        fill: $blue-2;
      }
    }

    &.profile-image-container {
      position: relative;
    }

    &.profile-photo {
      border: none;
      padding: 3rem 1.5rem;
      width: 120px;
      height: 120px;
    }

    &.profile-menu {
      min-height: 400px;
      background-color: $blue-1;
      text-align: left;
      flex: 1 0 20%;
      padding-top: 1rem;

      @media only screen and (max-width: 570px) {
        display: inherit;
        overflow: scroll;
        min-height: unset;
        padding-top: 0;
        text-wrap: nowrap;
        margin-bottom: 1.5rem;
      }

      &__content {
        flex: 0 1 100%;
        padding: 0 4rem;
        text-align: left;
        overflow: scroll;

        .reports-table {
          margin-bottom: 3rem;

          .report-state {
            &:hover {
              color: $green-1;
              cursor: pointer;
            }
          }
        }

        table {
          tr {
            vertical-align: baseline;
          }
        }

        @media only screen and (max-width: 570px) {
          padding: 0 2.5rem 4rem;

          .onr-student-page.content-body {
            width: unset;
          }
        }
      }

      .profile-menu--item {
        background-color: unset;
        color: white;
        padding: 1rem 2rem;
        width: 100%;
        font-size: 1.2em;
        border: none;
        cursor: pointer;
        text-align: left;

        @media only screen and (max-width: 500px) {
          padding: 1rem;
        }

        &:hover {
          background-color: $blue-2;
        }

        &.menu--selected {
          background-color: $blue-2;
        }
      }
    }
  }
}


.onr-body__create-form--full.contact-partner {
  width: 28rem;
}

.onr__contact-partner-container--form {
  max-width: fit-content;

  .send-message-button.contact-partner {
    float: right;
    margin-right: 1rem;
  }

  .input__container.contact-partner {
    @media only screen and (max-width: 1400px) {
      display: flex;
      flex-direction: column;

      .onr-body__create-form--full.contact-partner {
        width: 100%;
      }
    }
  }
}

.onr-body__create-form--full.eval-form {
  margin-left: unset;
  width: 100%;

  &[type='textarea'] {
    height: unset;
  }
}

.onr__eval-container--form {
  display: flex;
  flex-direction: column;

  .input__container.eval-form {
    display: flex;
    flex-direction: column;

    .input__label {
      font-size: 1em;
    }
  ;
  }
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

.submit-eval-form {
  background-color: $blue-1;
  color: white;
  font-size: 16px;
  border: solid 3px $blue-1;
  cursor: pointer;
  font-weight: 400;
  margin-top: 20px;
  padding: 13px 15px;
  text-transform: uppercase;
  float: left;
}

.edit-account {
  display: flex;
  flex-direction: column;
  margin: 0;
  overflow: scroll;
  max-width: 450px;

  &__button {
    background-color: $blue-1;
    border-radius: 25px;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 16px;
    height: 40px;
    margin-right: 0.5rem;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 200px;

    &.button--disabled {
      background-color: $grey-2;
      cursor: none;
      pointer-events: none;

      &:hover {
        background-color: $grey-2;
        border: none;
        cursor: unset;
      }
    }

    &:hover {
      background-color: $blue-1;
      border: solid 3px $blue-1;
      cursor: pointer;
    }
  }

  @media only screen and (max-width: 650px) {
    margin: 0;
  }

  &.checkboxes {
    flex-direction: row;

    @media only screen and (max-width: 650px) {
      flex-direction: column;
    }
  }

  @media only screen and (max-width: 1035px) {
    margin-bottom: 0;
  }

  .input__container {
    input {
      @media only screen and (max-width: 650px) {
        margin-left: unset;
      }
      width: 240px;
    }

    align-items: unset;

    select {
      @media only screen and (max-width: 650px) {
        margin-left: unset;
      }
      width: 256px;
    }

    @media only screen and (max-width: 650px) {
      flex-direction: column;
    }

    .input__label {
      @media only screen and (max-width: 650px) {
        align-items: baseline;
      }
    }

    .v3dp__datepicker {
      width: 256px !important;
      height: 35px !important;
      order: 2 !important;
      margin-left: auto !important;
      margin-right: 16px !important;

      @media only screen and (max-width: 650px) {
        //margin-left: auto !important;
        margin-right: 16px ! important;
        margin-left: 0 !important;
        width: 256px !important;
      }

    }
  }
  .dp__input_wrap{
    //height: 33px !important;
    margin-right: 30px !important;
    padding-right: 10px !important;
    .dp__input {
      border: none !important;
      height: 33px !important;
    }
    .dp__icon {
      margin-left: 0px !important;
    }
  }

  @media only screen and (max-width: 1035px) {
    overflow: unset;
  }

  .checkbox-container {
    margin-right: 2rem;

    @media only screen and (max-width: 1035px) {
      .interests-divs {
        display: flex;
      }
    }

    @media only screen and (max-width: 650px) {
      text-align: left;

      .subjects-div, .interests-divs {
        .onr-checkbox__container {
          text-align: left;
        }
      }
    }

    .subjects-div {
      margin-bottom: 2rem;
    }
  }

  &__divs {
    display: flex;
    flex-direction: row;
  }

  @media only screen and (max-width: 650px) {
    .edit-account__modal {
      width: 20em;
    }
  }
  // &__modal {
  // 	display: flex;
  // 	position: absolute;
  // 	position: fixed;
  // 	top: 0;
  // 	right: 0;
  // 	bottom: 0;
  // 	left: 0;
  // 	margin: auto;
  // 	text-align: center;
  // 	width: 90%;
  //   height: 80%;
  // 	background: #FFF;
  // 	z-index: 999;
  // 	transform: none;
  // 	flex-direction: column;
  //   overflow: scroll;

  // 	.update-profile-button {
  // 		background-color: $blue-1;
  // 		color: white;
  // 		font-size: 16px;
  // 		border: solid 3px $blue-1;
  // 		cursor: pointer;
  // 		font-weight: 400;
  // 		padding: 13px 15px;
  // 		text-transform: uppercase;
  // 		float: left;
  // 		margin: 2rem;
  // 	}

  // 	@media only screen and (max-width: 1035px) {
  // 		flex-direction: column;
  // 		overflow: scroll;
  // 	}

  // 	&.full-overlay {
  // 		justify-content: center;
  // 		width: 90%;
  // 		height: 40em;
  // 		overflow: scroll;

  // 		@media only screen and (max-width: 1035px) {
  // 			overflow: unset;
  // 		}
  // 	}
  // }
}
</style>